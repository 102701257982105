import React from "react"
import Seo from "../core/Seo"
import PricingContent from "../pricing/NewPage"
import { Section } from "../core/LayoutComponents"

const PricingPage = () => {
  return (
    <Section>
      <Seo
        title="We Sparkle • Free and Paid Plans | Compare Features"
        includeZohoTracking
      />
      <PricingContent />
    </Section>
  )
}

export default PricingPage
