import React from "react"
import styled from "styled-components"

const ToggleSlider = ({
  isOn,
  handleToggle,
  label,
  highlightColor = "#FAF9F6",
  inactiveColor = "#ccc",
  leftText = "Off",
  rightText = "On",
  justifyContent = "center",
  subtext,
}) => {
  return (
    <Col>
      <Row justifyContent={justifyContent}>
        <div
          style={{
            padding: "0 1rem 0 0",
          }}
        >
          {leftText}
        </div>
        <SliderSwitch color={highlightColor} color2={inactiveColor}>
          <input type="checkbox" checked={isOn} onChange={handleToggle} />
          <span className="slider" />
        </SliderSwitch>
        <div
          style={{
            padding: "0 0 0 1rem",
          }}
        >
          {rightText}
        </div>
      </Row>

      {subtext && <Subtext color={highlightColor}>{subtext}</Subtext>}
    </Col>
  )
}

export default ToggleSlider

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "center"};
  flex-direction: row;
  flex-wrap: nowrap;
`

const Col = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const Subtext = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.color || "#ccc"};
`
const SliderSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 29px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* Style the slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ color2 }) => color2};
    transition: 0.2s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 3px;
    bottom: 2px;
    background-color: #faf9f6;
    border: 1px solid #faf9f6;
    transition: 0.2s;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  }

  input:checked + .slider {
    background-color: ${({ color }) => color};
  }

  input:focus + .slider {
    box-shadow: 0 0 0 1px ${({ color2 }) => color2};
  }

  input:checked + .slider:before {
    transform: translateX(19px);
  }

  /* Hide the default focus ring on some browsers */
  input:focus:not(:focus-visible) + .slider {
    outline: none;
  }
`
