import React, { useEffect, useRef } from "react"
import styled from "styled-components"

import {
  useStickyHeaderContext,
  usePricingPlansContext,
} from "../context/pricing"

import PricingCards from "./PricingCards"
import FeaturesTable from "./FeaturesTable"
import ToggleSlider from "../core/Toggle"

import Faq from "../core/FAQs"
import { Content } from "../core/LayoutComponents"
import { Cost, Dollar, Subtext } from "../core/PricingComponents"
import { StickyHeader } from "../core/StickyHeader"

import { FREE_PLAN, ANNUAL_PLAN_SUFFIX } from "../utils/constants"
import { formatMonthlyPrice } from "../utils/pricing"

function PricingContent() {
  const { top, activeHeader, handleHeaderChange } = useStickyHeaderContext()
  const {
    plans,
    selectedPlan,
    selectedPlanColor,
    selectedPlanInactiveColor,
    setSelectedPlan,
    isAnnual,
    setIsAnnual,
    featuresTable,
  } = usePricingPlansContext()
  const pricingRef = useRef(null)
  const featuresRef = useRef(null)
  const faqRef = useRef(null)

  const headers = [pricingRef, featuresRef, faqRef]
  const headerIds = ["pricing-header", "solutions-header", "faq-header"]

  // for each header in array, if the next header reaches top, set activeHeader to that header
  useEffect(() => {
    const handleScroll = () => {
      // get the next header after activeHeader unless its the last one then leave it as the last one
      const nextActiveHeaderIndex = Math.min(
        headerIds.indexOf(activeHeader) + 1,
        headers.length - 1
      )

      // when nextActiveHeader hits top, set activeHeader to that header, if it's the last header, set to first header
      const nextActiveHeader = headerIds[nextActiveHeaderIndex]

      // check if nextActiveHeader is at top of screen
      const nextHeaderTop =
        headers[nextActiveHeaderIndex].current.getBoundingClientRect().top

      const threshold = 0 // or any value that works best for your use case
      if (nextHeaderTop <= top + threshold) {
        handleHeaderChange(nextActiveHeader)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleHeaderChange])

  return (
    <>
      <StickyHeader
        top={top}
        isSticky={activeHeader === "pricing-header"}
        ref={pricingRef}
        id="pricing-header"
      >
        <PricingHeader>
          <h1>Pricing</h1>
        </PricingHeader>
      </StickyHeader>
        <Content>
            <Quote>
            <p className="block-quote">
                <strong>
                Entrepreneurial Support Organizations
                </strong>
            </p>
            <p style={{alignContent:'left'}}>Please book a {" "}
            <HighlightLink
                color="white"
                href="https://sparkl.es/wesparkle"
            >
                {" "}<u>discovery call</u> 
            </HighlightLink>
                {" "}or email{" "}
            <HighlightLink
                color="white"
                href="mailto:hello@wesparkle.co">
                    <u>hello@wesparkle.co</u>
                </HighlightLink>
                {" "} for enterprise pricing.
            </p>
            </Quote>
        </Content>
      <PricingHeader style={{marginTop: "2rem"}}>
        <h4>Pick the perfect plan for your small business</h4>
        <ToggleSlider
            isOn={isAnnual}
            handleToggle={() => setIsAnnual(!isAnnual)}
            highlightColor={selectedPlanColor}
            inactiveColor={selectedPlanInactiveColor}
            leftText="Monthly"
            rightText="Annual"
          />
      </PricingHeader>
      <PricingCards />
      <StickyHeader
        top={top}
        isSticky={activeHeader === "solutions-header"}
        ref={featuresRef}
        id="solutions-header"
      >
        <TableHeader id="solutions">
          <h1>Features</h1>

          <TablePlans>
            {plans.map((plan, i) => {
              return (
                <TablePlan
                  key={`${plan.key}-solutions-${i}`}
                  selectedPlan={selectedPlan}
                  name={plan.key}
                  bgColor={plan.inactiveAccentColor}
                >
                  <TablePlanName color={plan.accentColor}>
                    {plan.name}
                  </TablePlanName>
                  <Cost>
                    <Dollar fontSize={"clamp(1.2rem, 2vw, 2.5rem)"}>
                      $
                      {isAnnual
                        ? plan.price.annual
                        : formatMonthlyPrice(plan.price.monthly)}
                      {plan.key !== FREE_PLAN && (
                        <sub>{!isAnnual ? "/mo" : "/yr"}</sub>
                      )}
                    </Dollar>
                    {/* <Subtext>{plan.price.subtext}</Subtext> */}
                  </Cost>
                </TablePlan>
              )
            })}
          </TablePlans>
        </TableHeader>
      </StickyHeader>

      <FeaturesTable />
    </>
  )
}

export default PricingContent

const PricingHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 1rem;
  z-index: 10;
  max-width: 65rem;
  margin: 0 auto;
  > h1 {
    line-height: 1;
    font-size: clamp(1.75rem, 5vw, 2.5rem);
  }
`
const HighlightLink = styled.a`
  color: ${(props) => props.color};
  & span {
    text-wrap: balance;
  }
`
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: start;

  border-bottom: 1px solid #3a506f;
  gap: 4rem;
  padding-inline: 1rem;
  max-width: 65rem;
  margin: 0 auto;

  p {
    font-size: 1rem;
    margin: 0;
  }
  @media screen and (max-width: 900px) {
    /* Should match height of sticky nav */
    top: 75px;
    grid-template-columns: 1fr;
    gap: 0;
    padding-block: 0;

    h2 {
      font-size: clamp(1.5rem, 4vw, 2.25rem);
    }
  }
`

const FaqHeader = styled.div`
  padding-inline: 1rem;
  border-bottom: 1px solid #3a506f;
  margin-block: 0.25rem;
  margin-inline: auto;
  max-width: 65rem;
`

const TablePlans = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
`
const TablePlan = styled.div`
  text-align: center;

  /* background-color: ${(props) =>
    props.name === props.selectedPlan ? props.bgColor : "none"};
  border-radius: ${(props) =>
    props.name === props.selectedPlan ? "10px" : "none"}; */

  h4 {
    margin: 0.5rem 0;
  }
  p {
    font-size: 12px;
    color: #a7b0be;
  }
  @media screen and (max-width: 900px) {
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 1rem;
    }
    p {
      font-size: 0.7rem;
    }
  }
`
const TablePlanName = styled.h3`
  color: ${({ color }) => color};
`
const Quote = styled.blockquote`
  background: linear-gradient(
    87.54deg,
    rgba(239, 71, 101, 0.9) -6.39%,
    rgba(55, 144, 226, 0.85) 51.12%,
    rgba(37, 213, 161, 0.85) 98.07%
  );
  background-size: 400% 400%;
  border-radius: 10px;
  padding: 5rem 2rem;
  text-align: center;
  margin-top: 4rem;
  animation: gradient 8s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media screen and (max-width: 900px) {
    text-align: left;
    padding: 2rem;
    p.quote-author {
      text-align: right;
    }
  }

  p.block-quote {
    font-size: 1.5rem;
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 1rem;

    @media screen and (max-width: 900px) {
      font-size: 1.25rem;
    }
  }
`