import React from "react"
import styled from "styled-components"

import { Content } from "../core/LayoutComponents"
import { StickyHeader } from "../core/StickyHeader"

import {
  useStickyHeaderContext,
  usePricingPlansContext,
} from "../context/pricing"

import { FREE_PLAN, ANNUAL_PLAN_SUFFIX } from "../utils/constants"

const FeaturesTable = ({ top = "30" }) => {
  const { plans, selectedPlan, isAnnual, featuresTable } =
    usePricingPlansContext()

  return (
    <Content top={top}>
      <TableFeatures>
        {featuresTable.map(({ featureHeading, featureList }, j) => {
          return (
            <FeatureSection key={j}>
              <h3 className="feature-heading">{featureHeading}</h3>
              {featureList.map(({ featureName, featurePlans }, k) => {
                return (
                  <FeatureItem key={k}>
                    <p>{featureName}</p>
                    <FeaturePlans>
                      {featurePlans.map((item, l) => {
                        return typeof item === "boolean" ? (
                          <FeaturePlan
                            key={l}
                            className={item ? "enabled" : "disabled"}
                          />
                        ) : (
                          <FeatureText key={l}>{item}</FeatureText>
                        )
                      })}
                    </FeaturePlans>
                  </FeatureItem>
                )
              })}
            </FeatureSection>
          )
        })}
      </TableFeatures>
    </Content>
  )
}

export default FeaturesTable

const TableFeatures = styled.div``
const FeatureSection = styled.div`
  border-bottom: 1px solid #3a506f;
  padding: 1rem 0;
  gap: 1rem;

  &:last-of-type {
    border-bottom: none;
  }
  .feature-heading {
    margin-block: 0.5rem;
  }
  p {
    font-size: 1rem;
  }
`
const FeatureItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 4rem;
  margin-bottom: 1rem;

  p {
    margin: 0;
    text-wrap: balance;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 0;
    border: 1px solid #cccccc;
    border-radius: 10px;

    p {
      padding: 0.5rem;
      border-bottom: 1px solid #ccc;
    }
  }
`
const FeaturePlans = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 2rem;

  /* background-color: ${(props) =>
    props.planIndex === props.selectedPlanIndex ? props.bgColor : "none"}; */

  @media screen and (max-width: 900px) {
    padding: 0.5rem;
  }
`

const FeatureText = styled.div`
  font-size: 0.85em;
  @media screen and (max-width: 900px) {
    /* font-size: 0.75em; */
    margin: auto;
  }
`
const FeaturePlan = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--sparkle-green);

  &:first-child {
    background-color: white;
  }
  &:nth-child(2) {
    background-color: var(--sparkle-yellow);
  }
  &:last-child {
    background-color: var(--sparkle-brightblue);
  }
  &.enabled {
    transform: rotate(45deg);

    &::after {
      position: absolute;
      content: "";
      display: block;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      width: 6px;
      height: 13px;
      border: solid var(--sparkle-darkblue);
      border-width: 0 2px 2px 0;
    }
  }
  &.disabled {
    background-color: #465b78;
    transform: rotate(-45deg);

    &::before,
    &::after {
      position: absolute;
      content: "";
      display: block;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      background-color: var(--sparkle-darkblue);
    }
    &::before {
      height: 12px;
      width: 1.5px;
    }
    &::after {
      height: 1.5px;
      width: 12px;
    }
  }
`
